import React from "react";
import chatbot_guide from "assets/chatbot_quickguide.pdf";
import "./style.scss"; // Assuming you will create a similar SCSS file for styling

const newsList = [
  {
    id: 1,
    title: "New Generative AI Chatbot",
    date: "July 20, 2024",
    summary:
      "We are thrilled to announce the release of our latest innovation, the Generative AI Chatbot. This cutting-edge technology is designed to revolutionize the way you interact with Myracle, offering a more intuitive and responsive experience. For detailed information on installation and usage, please refer to our",
    link: {
      url: chatbot_guide,
      text: "guide",
    },
  },
];

const NewsList = () => {
  return (
    <div className="news-list-container">
      <div className="news-list-title">LATEST NEWS</div>
      <ul className="news-items">
        {newsList.map(news => (
          <li key={news.id} className="news-item">
            <div className="news-date">{news.date}</div>
            <div className="news-title">{news.title}</div>
            <div className="news-summary">
              {news.summary}{" "}
              <a target="_blank" href={news.link.url}>
                {news.link.text}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsList;
